const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' }
};

//const apiPath = 'https://places-nepal-290be81f7552.herokuapp.com/api'
    const apiPath = 'http://localhost:8080/api'

export const getLocations = async (selected, toFetch) => {
    const url = apiPath + '/locations/' + toFetch + '/' + selected;
    try {
        const response = await fetch(url, requestOptions)
        return response;
    } catch (e) {
        console.error('There was an error', e);
    }
}

export const getStateInfo = async (state) => {
    const url = apiPath + '/places/state/' + state;

    try {
        const response = await fetch(url, requestOptions)
        return response;
    } catch (e) {
        console.error('There was an error', e);
    }
}

export const getDistrictInfo = async (district) => {
    const url = apiPath + '/places/district/' + district;

    try {
        const response = await fetch(url, requestOptions)
        return response;
    } catch (e) {
        console.error('There was an error', e);
    }
}


//export const getDistrictsByState = async (state) => {
//    const url = apiPath + '/places/areas/' + state;
//    try {
//        const response = await fetch(url, requestOptions)
//        return response;
//    } catch (e) {
//        console.error('There was an error', e);
//    }
//}

export const getPlacesByArea = async (area) => {
    const url = apiPath + '/places/' + area;

    try {
        const response = await fetch(url, requestOptions)
        return response;
    } catch (e) {
        console.error('There was an error', e);
    }
}

export const getPlacesByDistrict = async (district) => {
    const url = apiPath + '/places/' + district;

    try {
        const response = await fetch(url, requestOptions)
        return response;
    } catch (e) {
        console.error('There was an error', e);
    }
}

export const getPlaceDetails = async (id) => {
    const url = apiPath + '/places/place/' + id;

    try {
        const response = await fetch(url, requestOptions)
        return response;
    } catch (e) {
        console.error('There was an error', e);
    }
}
