import format from 'dateformat';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Card, CardActions, CardContent, Divider, Grid, CardMedia } from '@mui/material';
import { Link } from 'react-router-dom';
import mountain from '../../assets/mountain.jpg';

function ArticleCard({ ...articleData }) {
  const data = articleData.data;
  
  const article = {
    body: data.body,
    dateCreated: data.dateCreated,
    dateUpdated: data.dateUpdated,
    published: data.published,
    slugName: data.slugName,
    title: data.title,
    category: data.category,
    subcategory: data.subcategory,
    image: [],
    userId: data.userId,
    authorFirstName: data.firstName,
    authorLastName: data.lastName,
    }

  return (
      <Card sx={{ p: 0, minWidth: 250, minHeight: 150, width: '15rem', backgroundColor: '#eee'}}>
          <CardMedia
                component="img"
                alt="background image"
                height="140"
                image={article.image.length !== 0 ? article.image[0] : mountain}
                title="background image"
            />
          <CardContent>
                <Grid container>
                  <Grid item xs={6}>
                  <Typography variant="body1" sx={{ fontSize: 10 }} align='left'>
                                          {format(article.dateUpdated, 'mmm dd, yyyy')}
                                      </Typography>

                  </Grid>
                  <Grid item xs={6}>
                      <Typography variant="body1" sx={{ fontSize: 10 }} align='right'>
                          by: Admin
                        </Typography>
                  </Grid>
                </Grid>
              <Typography variant="body1" color="text.primary" sx={{ mb:1, mt: 3, minHeight: 80, fontWeight: 600 }}>
                  <Link style={{ textDecoration: 'none', color: '#000000' }} to={`/stories/${article.slugName}`}>{article.title}</Link>
              </Typography>

          </CardContent>
          <CardActions>
            <Grid container>
                <Grid item xs={12}>
                <Link
                      to={`/stories/${article.slugName}`}
                  >
                  <Button variant="outlined" size="small" sx={{ color: '#000000', textTransform: 'capitalize', minWidth: '100%', '&:hover': { backgroundColor: '#213785', color: '#fff' } }}>Read</Button>
                    {/* <Button variant="contained" sx={{ fontWeight: 700, color: '#000000', backgroundColor: '#eee', margin: 'auto', '&:hover': { backgroundColor: '#213785', color: '#fff' } }}>Read</Button> */}
                  </Link>
                </Grid>
            </Grid>


          </CardActions>
    </Card>
    )
}

export default ArticleCard